<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Płatność {{ payment.p_number }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-expansion-panels multiple v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ payment.p_number }} - Podsumowanie
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Numer płatności:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.p_number }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Status płatności:</v-list-item-title>
                      <v-list-item-subtitle v-if="isAdmin">
                        <v-chip
                          v-if="payment.date_payed"
                          class="ma-2"
                          color="green"
                          text-color="white"
                          @click="openPaymentStatusDialog"
                        >
                          Opłacone
                        </v-chip>

                        <v-chip
                          v-else
                          class="ma-2"
                          color="red"
                          text-color="white"
                          @click="openPaymentStatusDialog"
                        >
                          Nieopłacone
                        </v-chip>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-else>
                        <v-chip
                          v-if="payment.date_payed"
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          Opłacone
                        </v-chip>

                        <v-chip v-else class="ma-2" color="red" text-color="white">
                          Nieopłacone
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="!payment.date_payed">
                    <v-list-item-content>
                      <v-list-item-title>Termin realizacji płatności:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.date_expected }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="payment.date_payed">
                    <v-list-item-content>
                      <v-list-item-title>Data potwierdzenia płatności:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.date_payed }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Wartość netto:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.price_net }} zł</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Wartość brutto:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.price_gross }} zł</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Zlecenia objęte płatnością:</v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="" v-for="transport in transports" :key="transport.id">
                          <router-link :to="'/transport/' + transport.id">{{
                            transport.number
                          }}</router-link>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="payment.invoice_id">
                    <v-list-item-content>
                      <v-list-item-title>
                        Faktura:
                        <v-btn
                          color="blue"
                          icon
                          @click="editInvoiceDialog = !editInvoiceDialog"
                          v-if="isAdmin"
                        >
                          <v-icon> mdi-circle-edit-outline </v-icon>
                        </v-btn>
                        <v-btn color="red" icon @click="downloadInvoice()">
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="isAdmin">
                    <v-list-item-content>
                      <v-list-item-title>
                        Wydruk:
                        <v-icon @click="downloadPDF()"> mdi-file </v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-bottom-navigation>
      <v-btn :to="{ name: 'paymentsList' }" value="recent" v-if="isAdmin">
        <span>Powrót do listy</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn :to="{ name: 'allPayments' }" value="recent" v-else>
        <span>Powrót do listy</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <AddInvoiceDialog
      v-model="editInvoiceDialog"
      :payment="payment"
      @save="invoiceAdded()"
    ></AddInvoiceDialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- payment status dialog -->
    <PaymentStatus
      v-model="paymentStatusDialog"
      :payment="payment"
      @submit="editPaymentStatus"
    ></PaymentStatus>
  </v-container>
</template>

<script>
  import store from '../../store';
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';
  import axios from 'axios';
  import moment from 'moment';

  import AddInvoiceDialog from '../../components/AddInvoiceDialog.vue';
  import InfoSnackBar from '../../components/InfoSnackBar.vue';
  import PaymentStatus from '../../components/PaymentStatus.vue';

  export default {
    components: {
      InfoSnackBar,
      AddInvoiceDialog,
      PaymentStatus,
    },
    props: {
      source: String,
    },
    data: () => ({
      info: null,
      editInvoiceDialog: false,

      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      panels: [0],
      payment: {},
      transports: [],
      isAdmin: store.getters.isAdmin,

      paymentStatusDialog: false,
    }),
    methods: {
      getPayment: function () {
        axios({
          url: this.$store.state.url + '/api/payment/get',
          data: {
            token: this.$store.state.token,
            params: {
              payment_id: this.$route.params.id,
            },
          },
          method: 'POST',
        }).then(resp => {
          if (resp.data.status === 200) {
            this.payment = resp.data.payments[0];
            this.getTransports(this.payment.payment_id);

            if (this.payment.date_payed)
              this.payment.date_payed = moment(new Date(this.payment.date_payed)).format(
                'DD-MM-YYYY'
              );
            if (this.payment.date_expected)
              this.payment.date_expected = moment(new Date(this.payment.date_expected)).format(
                'DD-MM-YYYY'
              );
          } else {
            this.$router.push('/');
          }
        });
      },
      getTransports: function (id) {
        axios({
          url: this.$store.state.url + '/api/transport/get',
          data: {
            token: this.$store.state.token,
            type: 'all',
            params: {
              payment_id: id,
            },
          },
          method: 'POST',
        }).then(resp => {
          resp.data.transports.forEach(t => {
            this.transports.push({
              id: t.transport_id,
              number: t.t_number,
            });
          });
        });
      },
      downloadPDF: function () {
        axios({
          url: this.$store.state.url + '/api/payment/get_pdf',
          data: { token: this.$store.state.token, id: this.payment.payment_id, full: true },
          method: 'POST',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', `payment_${this.payment.p_number}.pdf`);
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else {
              if (resp.data.message) alert(resp.data.message);
              else alert('Niepowiodło się');
            }
          })
          .catch(() => {
            alert('Niepowiodło się');
          });
      },
      invoiceAdded() {
        this.info = {
          message: 'Faktura została dodana',
          status: 'success',
          timeout: 2000,
        };
      },
      downloadInvoice: function () {
        const invoice = this.payment.invoice_id;

        axios({
          url: this.$store.state.url + '/api/invoice/' + invoice,
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
          method: 'GET',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute(
                'download',
                resp.headers['content-disposition'].split('filename=')[1].split(';')[0].slice(1, -1)
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          })
          .catch(err => {
            // console.log(err);
            if (err.response)
              this.info = {
                message: err.response.data.message || 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
            else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          });
      },
      openPaymentStatusDialog: function () {
        this.paymentStatusDialog = true;
      },
      editPaymentStatus: function (status, date) {
        if (!this.payment) return;

        axios({
          url: this.$store.state.url + '/api/payment/edit_status',
          data: {
            token: this.$store.state.token,
            id: this.payment.payment_id,
            status: status,
            date: date,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.status === 200) {
              this.payment.date_payed = date;
              this.paymentStatusDialog = false;

              this.info = {
                message: resp.data.message,
                status: 'success',
                timeout: 1500,
              };
            } else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          })
          .catch(err => {
            if (err.response)
              this.info = {
                message: err.response.data.message || 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
            else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          });
      },
    },
    created() {
      this.getPayment();
    },
  };
</script>
<style>
  .v-expansion-panel .map {
    width: 100%;
    height: 300px;
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transition: 0.1s;
  }
  .v-expansion-panel.v-item--active .map {
    display: block;
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
  }
</style>
